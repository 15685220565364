@import "../../../variables.scss";

.page {
  composes: page from "../../../style/index.scss";
  justify-content: flex-end;
  background-color: $background-color;

  h2,
  button:not(:last-child) {
    margin-bottom: $spacing-xs;
  }
}

.centerText {
  composes: centerText from "../../../style/index.scss"
}
